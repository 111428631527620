// TODO: Shift to @cleartrip/ct-design

.Stencil {
    background: #efefef;

    &.has-shimmer {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
        background-size: 1200px 1200px;
        position: relative;
    }

    &.is-rounded {
        border-radius: 3px;
    }

    &.is-circular {
        border-radius: 200px;
    }

    &.is-dark {
        background: #484848;
    }
}

.Stencil.is-dark.has-shimmer {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: linear-gradient(135deg, #484848 8%, #555 18%, #484848 33%);
    background-size: 800px 104px;
    position: relative;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
