.dropdown-new {
    &__item-stroke {
        &--icon,
        &--label {
            color: #1a1a1a;
        }

        &:hover {
            background-color: #f3f3f3;
            color: #1a1a1a;

            .dropdown-new__item--icon,
            .dropdown-new__item--label {
                color: #36c;
            }

            stroke: #3366cc;
        }

        stroke: #808080;
    }

    input {
        height: 52px !important;
    }
}

.dropdown-new {
    &__item-fill {
        &--icon,
        &--label {
            color: #1a1a1a;
        }

        &:hover {
            background-color: #f3f3f3;
            color: #1a1a1a;

            .dropdown-new__item--icon,
            .dropdown-new__item--label {
                color: #36c;
            }

            fill: #3366cc;
        }

        fill: #808080;
    }

    input {
        height: 52px !important;
    }
}

.h-13 {
    height: 52px !important;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin font-smoothing($value: antialiased) {
    @if $value ==antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

.searchBar {
    @include placeholder {
        @include font-smoothing;
        color: #808080;
        font-size: 16px;
        font-weight: 500;
        line-height: 20;
    }

    font-size: 16px;
    padding-left: 16px;
}

.searchBar_Wrapper {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: flex;
    align-items: center;

    &_active {
        border: 1px solid #36c;
    }
}

.Search__New {
    @include placeholder {
        @include font-smoothing;
        color: #808080;
        font-size: 16px;
        font-weight: 500;
        line-height: 20;
    }

    font-size: 16px;
    padding-left: 16px;
}

.Search_Wrapper {
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    display: flex;
    align-items: center;

    &_active {
        border: 1px solid #36c;
    }
}

.place__wrapper {
    &:hover {
        background-color: #f3f3f3;

        .place__icon {
            svg {
                color: #36c;
            }
        }
    }
}

.not-selectable {
    cursor: default !important;
}

// TODO
//  Temp fix for placeholder position
//  should be removed after fixing design system for
//  text field

.field-root.field-root {
    outline-width: 1px;
    display: flex;
    column-gap: unset;
    gap: 12px;
    &:hover {
        outline-width: 1px;
        outline-color: #e7e7e7;
        svg.c-pointer {
            path {
                stroke: #1a1a1a;
            }
        }
    }
}

.override-svg svg.c-pointer {
    path {
        stroke: 808080;
    }
}

.override-svg-focused {
    .field-root.field-root {
        outline-color: #1a1a1a;
        box-shadow: 0px 4px 16px 0px #1a1a1a14;
        &:hover {
            outline-width: 1px;
            outline-color: #1a1a1a;
        }
    }
    svg.c-pointer {
        path {
            stroke: #1a1a1a;
        }
    }
}

.modifyfilterSuggestions {
    position: relative;
    &:hover {
        background-color: #f3f3f3;
        color: #1a1a1a;
        fill: #3366cc;
    }
    &::after {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        left: 55px;
        width: 1px;
        background-color: #e6e6e6;
    }
    &.last::after {
        height: 10px;
    }
    &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        top: 7px;
        left: 55px;
        border-width: 1px;
        border-color: transparent transparent #e6e6e6 #e6e6e6;
        border-radius: 30% 70% 0% 100%/0% 0% 100% 100%;
        border-style: solid;
    }
    &.last {
        border-width: 0;
    }
}
