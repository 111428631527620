// dateRangePicker styles
:root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: caption;
    font-size: 12px;
    --arrow-value: 5px;
}

.awssld {
    --organic-arrow-thickness: 3px;
    --organic-arrow-height: 8px;
    --slider-height-percentage: 60%;
    --loader-bar-color: #851515;
    --loader-bar-height: 6px;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #6a6a6a;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #6a6a6a;
    --content-background-color: #6a6a6a;
}

.awssld__bullets {
    bottom: -1px !important;
    z-index: 9;
}

.awssld__controls {
    position: relative;

    margin-left: 28px;
    margin-right: 28px;
    top: 39px;
}

.awssld {
    --organic-arrow-thickness: 3px !important;
    --organic-arrow-height: 8px !important;
    --loader-bar-height: 6px !important;
    --control-button-height: 8% !important;
    --control-bullet-color: #ccc !important;
    --control-bullet-active-color: #333 !important;
    height: 200px !important;
}

.awssld__bullets button {
    width: 6px !important;
    height: 6px !important;
    background: #dbdbdb !important;
    border-radius: 30px !important;
}

.awssld__bullets .awssld__bullets--active {
    width: 12px !important;
    height: 6px !important;
    background: #b3b3b3 !important;
    border-radius: 30px !important;
}

.carousalImg {
    .awssld__bullets button {
        width: 6px !important;
        height: 6px !important;
        background: rgba(255, 255, 255, 0.7) !important;
        border-radius: 30px !important;
    }

    .awssld__bullets .awssld__bullets--active {
        width: 12px !important;
        height: 6px !important;
        background: #ffffff !important;
        border-radius: 30px !important;
    }

    .awssld__bullets {
        bottom: 16px !important;
        z-index: 9;
    }

    .awssld__controls {
        position: relative;
        top: 22px;
        margin-left: var(--arrow-value);
        margin-right: var(--arrow-value);
    }
}

a.weak {
    color: #85a3e0;
}

a.blue {
    color: #36c;
}

.lineMessage {
    font-size: 1.3em;
    line-height: 1.4em;
    border-radius: 0.3em;
    padding: 7px 10px;
    position: relative;
    font-weight: 400;
    border: 1px solid #e4dcb2;
    background-color: #ffc;
    background-image: -moz-linear-gradient(#ffc, #f8f6c3);
    background-image: -webkit-linear-gradient(#ffc, #f8f6c3);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffc), to(#f8f6c3));
    background-image: linear-gradient(#ffc, #f8f6c3);
    margin: 0;
    border-bottom: 1px solid #d3c789;
}

.humbger {
    border-style: solid;
    border-width: 1px 0px 1px 1px;
    border-color: #e6e6e6;
}

.h-fc {
    height: fit-content;
}

.ml-125 {
    margin-left: 125px;
}

.mr-125 {
    margin-right: 125px;
}

.pr-115 {
    margin-left: 115px;
}

.ml-200 {
    margin-left: 200px;
}

.mbm-41 {
    margin-bottom: -41px;
}

.ml-90 {
    margin-left: 90px;
}

.bb_dotted {
    border-bottom: 1px dotted #ddd;
}

.ta-left {
    text-align: left;
}

.ta-right {
    text-align: right;
}

.c-neutral-200 {
    color: #cccccc;
}

.DayPicker {
    &-wrapper {
        padding-bottom: 8px;
    }
    &-Day {
        width: 51px;
        height: 51px;
        max-width: 51px;
        animation: zoomIn 0.3s 1 ease-in-out;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        color: #1a1a1a;
        // padding: 4px;
        border-radius: 4px;
        //border-right: 1px solid #f7f7f7;
        margin-bottom: 4px;
        &--disabled {
            color: #e5e5e5;
            cursor: default;
            pointer-events: none;
            .Datepicker--price {
                display: none;
            }
        }
        &:hover {
            .Datepicker--price {
                color: white;
            }
        }
        .Day-grid {
            .day-gridContent {
                width: 44px;
            }
        }
    }
    &-Caption {
        text-align: center;
        margin-bottom: 8px;
        & > div {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            color: #1a1a1a;
            animation: zoom-in 0.3s 1 ease-in-out;
        }
    }
    &-Weekday {
        padding: 13px 12px;
        font-size: 14px;
        line-height: 20px;
        color: #999;
        text-transform: uppercase;
    }
    &-Months {
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 4px;
    }
    &-Month {
        border-collapse: collapse;
        border-spacing: 0;
        margin: 0;
    }
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}
.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected {
    .day-gridContent {
        border-radius: 4px;
        &.Round-trip {
            border-radius: 4px;
        }
    }
}
.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected {
    .day-gridContent {
        border-radius: 4px;
        &.Round-trip {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 6px solid #d6e8fc;
            position: absolute;
            right: -3px;
            width: 50px;
        }
    }
}

.DayPicker-Day.DayPicker-Day--leftEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
    left: 3px !important;
    width: 51px !important;
    border-right: 7px solid #d6e8fc !important;
    border-left: 0px solid #d6e8fc !important;
}

.DayPicker-Day.DayPicker-Day--rightEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
    left: -1px !important;
    width: 48px !important;
    border-right: 0px solid #d6e8fc !important;
    border-left: 6px solid #d6e8fc !important;
}
.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected .day-gridContent.Round-trip {
    border-left: 0px solid #d6e8fc;
    border-right: 0px solid #d6e8fc;
    left: 3px;
    width: 44px;
    border-radius: 4px !important;
}
.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--leftEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
    left: 3px !important;
    width: 44px !important;
    border-right: 0px solid #d6e8fc !important;
    border-left: 0px solid #d6e8fc !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--rightEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
    left: 3px !important;
    width: 44px !important;
    border-right: 0px solid #d6e8fc !important;
    border-left: 0px solid #d6e8fc !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected {
    .day-gridContent {
        border-radius: 4px;
        &.Round-trip {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            position: absolute;
            left: 2px;
            width: 45px;
            border-left: 1px solid #d6e8fc;
        }
    }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: white;
    background-color: transparent;

    .Day-grid {
        margin: 0 auto;
        .day-gridContent {
            background-color: #36c;
            border-radius: 4px;
        }
    }
    .Datepicker--price {
        color: white;
    }
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--end):not(.DayPicker-Day--start) {
    color: white;
    background-color: transparent;

    .Day-grid {
        margin: 0 auto;
        .day-gridContent {
            background-color: #36c;
            border-radius: 4px;
        }
    }
    .Datepicker--price {
        color: #666;
    }
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: transparent;
    color: white;

    .Day-grid {
        margin: 0 auto;
        .day-gridContent {
            background-color: #36c;
            border-radius: 4px;
        }
    }
}
.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #ffffff !important;
    color: white;

    .Day-grid {
        margin: 0 auto;
        .day-gridContent {
            border-radius: 4px;
            background-color: #36c;
            &.Round-trip {
                background-color: #36c;
                color: white;
            }
        }
    }
}
.DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected .day-gridContent.Round-trip.monthEnd {
    border-right: 0px solid #d6e8fc !important;
    position: absolute;
    left: 3px !important;
    border-radius: 4px !important;
    width: 44px;
}
.DayPicker-Day.DayPicker-Day--selected:hover .day-gridContent.Round-trip {
    background: #36c !important;
    color: #fff;
}
.Selectable {
    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--rightEdge.DayPicker-Day--selected .Round-trip.day-gridContent {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .DayPicker-Day.DayPicker-Day--leftEdge.DayPicker-Day--selected .day-gridContent.Round-trip.monthEnd {
        left: 3px !important;
        width: 44px !important;
        border-right: 0px solid #d6e8fc !important;
        border-left: 0px solid #d6e8fc !important;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)
        .Day-grid
        .day-gridContent.monthEnd {
        position: absolute;
        left: -4px;
        width: 51px;
        border-right: 0px solid #d6e8fc;
        border-left: 8px solid #d6e8fc;
    }
    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected .day-gridContent.Round-trip.monthStart {
        width: 44px;
        position: absolute;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-left: 0px;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)
        .Day-grid
        .day-gridContent.monthStart {
        position: absolute;
        left: 3px;
        width: 51px;
        border-right: 7px solid #d6e8fc;
        border-left: 0px solid #d6e8fc;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: transparent;

        .Day-grid {
            border-left: 0px;
            border-right: 0px;
            border-radius: 0;
            border-top-left-radius: 0;
            .day-gridContent {
                position: absolute;
                border-right: 6px solid #d6e8fc;
                border-left: 0px solid #d6e8fc;
                left: 2px;
                width: 51px;
                border-radius: 0px;
                background-color: #d6e8fc;
            }
        }
    }
    .DayPicker-Month {
        border-collapse: collapse;
        border-spacing: 0 4px;
    }
    .DayPicker-Day {
        border-radius: 0 !important;
        &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled) {
            color: #1a1a1a !important;
        }
        &:hover:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
            border-radius: 8px !important;
            background: #d6e8fc !important;
        }
    }

    .DayPicker-Day--start,
    .DayPicker-Day--leftEdge:not(.DayPicker-Day--end) {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    .DayPicker-Day--leftEdge:not(.DayPicker-Day--start) {
        .day-gridContent {
            border-radius: 0px;
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
        }
    }

    .DayPicker-Day--rightEdge:not(.DayPicker-Day--start) {
        .day-gridContent {
            border-radius: 0px;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    .DayPicker-Day--end,
    .DayPicker-Day--rightEdge:not(.DayPicker-Day--start) {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
}

.Selectable--rtl {
    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--end.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-left: 0px solid #d6e8fc;
        border-right: 0px solid #d6e8fc;
        left: 3px;
        width: 44px;
        border-radius: 4px;
    }
    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected .day-gridContent.Round-trip.monthStart {
        width: 44px;
        position: absolute;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
        border-right: 0px;
    }
    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected .day-gridContent.Round-trip.monthEnd {
        border-right: 0px solid #d6e8fc !important;
        right: 3px !important;
        left: 0px !important;
        border-left: 0px !important;
        width: 44px !important;
    }
    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right: 0px solid #d6e8fc;
        border-left: 6px solid #d6e8fc;
        position: absolute;
        right: 3px;
        width: 50px;
    }

    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        position: absolute;
        left: 3px;
        width: 50px;
        border-left: 0px;
        border-right: 6px solid #d6e8fc;
    }

    .DayPicker-Day.DayPicker-Day--end.DayPicker-Day--leftEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
        left: 3px !important;
        width: 44px !important;
        border-right: 0px solid #d6e8fc !important;
        border-left: 0px solid #d6e8fc !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--rightEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
        left: 3px !important;
        width: 44px !important;
        border-right: 0px solid #d6e8fc !important;
        border-left: 0px solid #d6e8fc !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
    .DayPicker-Day.DayPicker-Day--start.DayPicker-Day--selected .day-gridContent.Round-trip {
        position: absolute;

        .day-gridContent {
            border-radius: 4px;
            &.Round-trip {
                // border-top-left-radius: 4px !important;
                // border-bottom-left-radius: 4px !important;
                // border-top-right-radius: 0px !important;
                // border-bottom-right-radius: 0px !important;
                border-right: 6px solid #d6e8fc;
                position: absolute;
                right: -3px;
                width: 50px;
            }
        }
    }
    .DayPicker-Day.DayPicker-Day--leftEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-bottom-right-radius: 4px !important;
        border-top-right-radius: 4px !important;
        right: 3px !important;
        width: 53px !important;
        border-left: 6px solid #d6e8fc !important;
        border-right: 0px solid #d6e8fc !important;
    }
    .DayPicker-Day.DayPicker-Day--rightEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
    }

    .DayPicker-Day.DayPicker-Day--rightEdge.DayPicker-Day--selected .day-gridContent.Round-trip {
        left: 3px !important;
        width: 50px !important;
        border-right: 6px solid #d6e8fc !important;
        border-left: 0px solid #d6e8fc !important;
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)
        .Day-grid
        .day-gridContent.monthEnd {
        position: absolute;
        left: 3px !important;
        width: 53px;
        border-right: 8px solid #d6e8fc;
        border-left: 0px solid #d6e8fc;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)
        .Day-grid
        .day-gridContent.monthStart {
        position: absolute;
        right: 3px;
        width: 53px;
        border-right: 0px solid #d6e8fc;
        border-left: 7px solid #d6e8fc;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: transparent;

        .Day-grid {
            // position: absolute;
            // top: 3px;
            // left: -6px;
            // width: 65px !important;
            border-left: 0px;
            border-right: 0px;
            border-radius: 0;
            border-top-left-radius: 0;
            .day-gridContent {
                position: absolute;
                border-right: 3px solid #d6e8fc;
                border-left: 0px solid #d6e8fc;
                left: 2px;
                width: 51px;
                border-radius: 0px;
                background-color: #d6e8fc;
            }
        }
    }
    .DayPicker-Month {
        border-collapse: collapse;
        border-spacing: 0 4px;
    }
    .DayPicker-Day {
        border-radius: 0 !important;
        &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled) {
            color: #1a1a1a !important;
        }
        &:hover:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
            border-radius: 8px !important;
            background: #d6e8fc !important;
        }
    }

    .DayPicker-Day--start,
    .DayPicker-Day--leftEdge:not(.DayPicker-Day--end) {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    .DayPicker-Day--leftEdge:not(.DayPicker-Day--start) {
        .day-gridContent {
            border-radius: 0px;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    .DayPicker-Day--rightEdge:not(.DayPicker-Day--start) {
        .day-gridContent {
            border-radius: 0px;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
        }
    }

    .DayPicker-Day--end,
    .DayPicker-Day--rightEdge:not(.DayPicker-Day--start) {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }

    // .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    //   background-color: #D6E8FC !important;
    //   }
    .DayPicker-Month {
        border-collapse: collapse;
        border-spacing: 0 4px;
    }
    // .DayPicker-Day {
    //   border-radius: 0 !important;
    //   &:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--disabled) {
    //     color: #1A1A1A !important;
    //   }
    //   &:hover:not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
    //     border-radius: 8px !important;
    //     background: #D6E8FC !important;
    //   }
    // }
    // .DayPicker-Day--start,.DayPicker-Day--leftEdge:not(.DayPicker-Day--end) {
    //   border-top-right-radius: 8px !important;
    //   border-bottom-right-radius: 8px !important;
    // }

    // .DayPicker-Day--end, .DayPicker-Day--rightEdge:not(.DayPicker-Day--start){
    //   border-top-left-radius: 8px !important;
    //   border-bottom-left-radius: 8px !important;
    // }
}
