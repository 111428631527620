@mixin slide-up-animation($delay) {
    opacity: 0;
    transform: translateY(100%);
    animation: slideUpAnimation 0.75s ease forwards;
    animation-delay: $delay;
}

@keyframes slideUpAnimation {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
.slide-up-1-5 {
    @include slide-up-animation(1.5s);
}
.slide-up-1-6 {
    @include slide-up-animation(1.6s);
}
.slide-up-1-7 {
    @include slide-up-animation(1.7s);
}
.slide-up-1-8 {
    @include slide-up-animation(1.8s);
}
.slide-up-0-5 {
    @include slide-up-animation(0.5s);
}
.slide-up-0-25 {
    @include slide-up-animation(0.25s);
}
.slide-up-0-75 {
    @include slide-up-animation(0.75s);
}

.ba {
    border: solid 1px #eaeaea;
}

.border-animation {
    position: relative;
    padding: 1px;
    border-radius: 12px;
    overflow: hidden;

    &-1,
    &-2,
    &-3,
    &-4 {
        position: absolute;
        background-color: #0048bd;
        z-index: 0;
        animation: border-animation 1s linear forwards;
    }

    &-1 {
        height: 0;
        width: 1px;
        bottom: 50%;
        left: 0;
        animation-name: border-fill-1;
    }

    &-2 {
        height: 1px;
        width: 0;
        left: 50%;
        top: -1px;
        animation-name: border-fill-2;
        animation-delay: 1s;
    }

    &-3 {
        height: 1px;
        width: 0;
        top: 50%;
        right: 0;
        animation-name: border-fill-3;
        animation-delay: 2s;
    }

    &-4 {
        height: 1px;
        width: 0;
        bottom: 0;
        right: 50%;
        animation-name: border-fill-4;
        animation-delay: 3s;
    }
}

@keyframes border-fill-1 {
    0% {
        height: 0;
        width: 1px;
    }
    30% {
        height: 50%;
        width: 10px;
    }
    100% {
        height: 55%;
        width: 50%;
    }
}

@keyframes border-fill-2 {
    0% {
        height: 3px;
        width: 1px;
    }
    70% {
        height: 10px;
        width: 50%;
    }
    100% {
        height: 55%;
        width: 50%;
    }
}

@keyframes border-fill-3 {
    0% {
        width: 0px;
        height: 1px;
    }
    40% {
        height: 50%;
        width: 10px;
    }
    100% {
        height: 50%;
        width: 50%;
    }
}

@keyframes border-fill-4 {
    0% {
        width: 0px;
        height: 1px;
    }
    60% {
        height: 3px;
        width: 50%;
    }
    100% {
        height: 55%;
        width: 50%;
    }
}
